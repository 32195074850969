import React from "react";
import ExpoCell from "../components/ExpoCell";

import 'locomotive-scroll/dist/locomotive-scroll.css';

export default function Home() {

    const [menuOpener, setMenuOpener] = React.useState(false);

    return (
        <>
            {/* navbar */}
            <div className='fixed z-40 flex flex-row justify-between w-full h-12 p-2'>
                <div className="container flex flex-row items-center justify-between">
                    <div className="flex items-center gap-2" >
                        <a href="/" className="flex items-center gap-2 font-bold tracking-tight uppercase">
                            <img alt="kgdm-logo" className='flex h-8 -mt-1' src='https://res.cloudinary.com/kgdm/image/upload/v1699049166/KGDM_Logos_cel2up_ufpfo1.png' />
                            Jose Aljovin
                        </a>
                        <span>Perú</span>
                    </div>
                    <div onClick={() => { setMenuOpener(!menuOpener) }} className="text-xs font-bold uppercase">menu</div>
                </div>
            </div>


            <div className="flex flex-col snap-y snap-mandatory">

                {/* header */}
                <div className="flex bg-right bg-no-repeat bg-contain snap-center" style={{ backgroundImage: `url("https://res.cloudinary.com/kgdm/image/upload/v1699049166/bg_gzvocv_h0tt6z.jpg")` }} >
                    <div className="container flex flex-col items-start justify-center min-h-screen gap-4 bg-cover" >
                        <div>
                            <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049166/74687696_2449371788519524_7043466393701842944_n_utao61_rivdpp.jpg" alt="Jose Aljovin" className="w-32 h-32 rounded-full" />
                        </div>
                        <div className="text-5xl font-bold tracking-tighter">
                            <div>Design coach,</div>
                            <div>Product Lead Designer</div>
                            <div>And Fullstack Developer</div>
                        </div>
                        <div className="max-w-md text-sm">
                            Compasión es el detonante de mi trabajo.
                            Desarrollemos un producto relevante para las personas
                        </div>
                        <a className="p-2 px-4 mt-8 font-bold text-white transition-all bg-black hover:scale-110 hover:bg-primary " href="https://wa.me/51993375762">Contacto para negocios</a>
                        <a className="mt-4 text-xs font-bold underline text-primary" href="https://wa.me/51993375762">Contacto para mentoring</a>
                    </div>
                </div>

                {/* experoiencia */}
                <div className="container z-10 flex flex-col gap-2 p-12 bg-white snap-center">
                    <div className="text-xs font-black tracking-widest uppercase">He tenido el gusto de trabajar con</div>
                    <div className="text-4xl font-bold leading-10 tracking-tighter uppercase">
                        Global financing facility · MACLima · BANCO DE CRÉDITO · TRIBAL DDB · AUDI · MIAMIADSCHOOL LIMA · PROMPERU · PLANNEX · POSITIVE COMMUNICATIONS · TOULOUSE-LAUTREC · IPD · ISIL · MOVISTAR · LosPortales · MUSEO PEDRO DE OSMA · ICONO · PRESTAMYPE · NISSAN · INBI · MAPSALUD · CENTRAL RESTAURANTE · AREA51 · BBVA · CIRCUS · INVOLUCRA · DANONE · ALMONATURE · TOURING · MILLENNIAL · UPC · SMARTCLICK · ENTEL · SUPERPET · MAQUINARIAS · FASTLANE · TAMBO · MARRIOT LIMA · JWT · PERU21 ·
                    </div>
                    <div>y más de 65 marcas en 16 paises</div>
                </div>

                {/* tech */}
                <div className="container flex flex-col pt-12 md:flex-row snap-center">

                    <div className="flex flex-col gap-2 p-12 duration-500 bg-primary hover:scale-110 hover:z-50">
                        <div className="mb-4 text-xs font-black tracking-widest">
                            <div className="uppercase ">Developement</div>
                            <div className="text-xs tracking-normal" >llevando del tablero de diseño a Usuarios finales con las siguientes tecnologias</div>
                        </div>
                        <div className="mb-4 text-3xl font-bold tracking-tight">React · Vue · Strapi · Express · MongoDB · MySQL · ReactNative · Swift · Rust · Node.js · Php · AWS · Git</div>
                        <div className="font-bold text-xxs opacity-70">Honesto detractor de .net por la fuga de recursos que genera.</div>
                    </div>

                    {/* capital */}
                    <div className="flex flex-col justify-center gap-2 p-12 duration-700 bg-black text-primary hover:scale-110 hover:z-50" >
                        <div className="text-xs font-black tracking-widest text-white uppercase">CAPITAL INTELECTUAL</div>
                        <div className="text-3xl font-bold tracking-tight">KGDM® NETWORK, YIICARD, KGDMLIVE® HONEST DESIGN LEARNING, </div>
                        <div className="text-3xl font-bold tracking-tight">MINDSET SERIES, VIVIR COMO DISEÑADOR, MOREWITHLESS SERIES.</div>
                        <div className="text-3xl font-bold tracking-tight">BACKLOG</div>
                    </div>

                </div>

                {/* producciones */}
                <div className="flex flex-col max-w-screen snap-center" >
                    <div className="container py-10">
                        <div className="py-12 text-2xl font-black tracking-widest text-center text-black uppercase">Producciones originales</div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-row gap-4">
                            <div className="flex w-2/3 ml-8 bg-primary aspect-video">
                                <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=XWrLhl-J1-U&list=PLPp6BhZdoJw770YI-Lk_ufXEQuvWMTSwZ&ab_channel=josealjovin">
                                    <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049166/1_k5zbjc_w4ijc1.jpg" alt="mindset" />
                                </a>
                            </div>
                            <div className="flex flex-col w-1/3 gap-4">
                                <div className="flex mr-16 bg-primary aspect-video">
                                    <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=2cohOaALMhs&list=PLPp6BhZdoJw62HBhGQINoUosFIuhaK5cO&index=8&ab_channel=josealjovin">
                                        <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/2_fqqqbf_wn5l0s.jpg" alt="More with Less" />
                                    </a>
                                </div>
                                <div className="flex mr-16 bg-primary aspect-video">
                                    <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=QVpqQiCI5u0&t=4s&ab_channel=DanielaAljovin">
                                        <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/3_bp7pva_jc8zc8.jpg" alt="daniela Aljovin" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="flex flex-col w-1/3 gap-4 ">
                                <div className="flex ml-10 bg-primary aspect-video">
                                    <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=qG8UUYURsfA&list=PLPp6BhZdoJw6diu-d2jcAuNwrhSpFuXiO&ab_channel=josealjovin">
                                        <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/5_riqq6m_hsw9qp.jpg" alt="vivir como diseñador" />
                                    </a>
                                </div>
                                <div className="flex ml-10 bg-primary aspect-video">
                                    <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=POtrminmhtM&t=2s&ab_channel=CarlosFelipeBarrientosCruz">
                                        <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/4_h3kvqp_fzvceb.jpg" alt="ipd futbol fest" />
                                    </a>
                                </div>
                            </div>
                            <div className="flex w-2/3 mr-10 bg-primary aspect-video">
                                <a className="hover:animate-pulse" target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=N2gFHcnlMhk&t=21s&ab_channel=josealjovin">
                                    <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/6_t5utpo_abmh3z.jpg" alt="day in the life" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                {/* crehana */}
                <div>

                    <div className="pt-12 text-2xl font-black tracking-widest text-center text-black uppercase" >Cursos disponibles</div>
                    <div className="container flex flex-col items-center gap-4 py-12 md:flex-row" >
                        <div className="flex md:w-3/5 aspect-video">
                            <video src={'https://res.cloudinary.com/kgdm/video/upload/v1699049167/e06c7b0ab9d5182bccffc612111b172d7b9af283_j0ctyf_vly542.mp4'} controls className="object-cover w-full" />
                        </div>
                        <div className="flex flex-col items-start justify-start gap-4">
                            <div className="font-bold"> CURSOS DISPONIBLES</div>
                            <div>SKETCH DE CERO A EXPERTO EN CREHANA</div>
                            <div className="text-xxs">disponible en</div>
                            <img className="w-32 h-auto" src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/download-300x59_vobp3x_jbdpya.png" alt="crehana" />
                            <a className="p-2 px-4 font-bold text-white bg-black" href="https://www.crehana.com/pe/cursos-online-ui-ux/sketch-de-cero-a-experto-disenando-interfaces/?af=5bb71dab-e96d-4e3c-8e58-fee81caf69e4">
                                Ir a Curso
                            </a>
                        </div>
                    </div>
                </div>

                {/* ponencias */}
                <div className="container flex flex-col gap-2 p-12 bg-primary">
                    <div className="mb-4 text-xs font-black tracking-widest text-white uppercase">PONENCIAS</div>
                    <div className="flex flex-col justify-between gap-4 md:flex-row">
                        <div className="grid grid-cols-2 gap-2">
                            <ExpoCell title="Figma Meetup Lima III" detail="Crowdy – Tailoi Auditorio" />
                            <ExpoCell title="Figma Meetup Lima IV" detail="Laboratoria" />
                            <ExpoCell title="Crehana Live – User Experience" detail="enero 2019" />
                            <ExpoCell title="Expo Textil Jockey Plaza – Brand Storytelling" detail="Noviembre 2019" />
                            <ExpoCell title="Dribbble Meetup Lima II" detail=" Jun 2019" />
                            <ExpoCell title="Sketch Meetup" detail="Rimac Seguro" />
                            <ExpoCell title="Picnic Design, host" detail="  enero 2019" />
                            <ExpoCell title="BBVA Design Disruptors" detail=" jun-2019" />
                            <ExpoCell title="Miami adSchool Lima, UX bootcamp" detail=" mid-2018" />
                            <ExpoCell title="Miami adSchool Lima, UX bootcamp" detail=" mid-2017" />
                            <ExpoCell title="Dribbble Meetup Lima II" detail=" Jun 2019" />
                            <ExpoCell title="Lima Ilustra  – Freelancers al poder" detail="Sep 2016" />
                            <ExpoCell title="Foodstyling – Lima Visual 2019" detail=" Octubre 2019" />
                            <ExpoCell title="Lima visual 2019 – Personal Brand Content" detail=" Octubre 2019" />
                            <ExpoCell title="Lima Visual 2019 – Foodstyling" detail=" Octubre 2019" />
                            <ExpoCell title="Lima visual 2018 – Personal Brand Content" detail="Octubre 2018" />
                            <ExpoCell title="Lima visual 2018 – Foodstyling" detail="Octubre 2018" />
                            <ExpoCell title="Lima visual 2018 – Tagging + Branding" detail="Octubre 2018" />
                            <ExpoCell title="Lima visual 2017 – Personal Brand Content" detail="Octubre 2018" />
                            <ExpoCell title="Lima visual 2017 – Foodstyling" detail="Octubre 2018" />
                            <ExpoCell title="Lima visual 2017 – Tagging + Branding" detail="Octubre 2018" />
                        </div>
                        <div>
                            <img src="https://res.cloudinary.com/kgdm/image/upload/v1699049167/IMG_8355_t0fuht_mbm1ki.jpg" alt="crehana" />
                        </div>
                    </div>
                </div>

                {/* footer */}
                <div className="container flex flex-col gap-2 p-4 pb-12 text-xs font-semibold tracking-tight text-gray-600">
                    <div className="flex font-bold uppercase tet-xxs ">jose aljovin - Kgdm</div>
                    <div className="text-gray-500 text-xxs">
                        <span className="text-primary">kingdomelement™ </span>
                        es parte del patrimonio de la familia Aljovin.</div>
                    <div className="w-2/3 leading-4 text-gray-500 text-xxs">KGDM™network y el modelo de trabajo que propone es patrimonio compartido con los participantes acreditados, Los derechos sobre DLCS™kgdm, Osmosis™kgdm, Studio, Simple™kgdm, S4R4, Bertrha™kgdm, UIStats, kgdmlive, enterthe.studio, son propiedades de sus propios dueños</div>
                </div>

            </div>

            <div className={` duration-1000 text-lg gap-3 font-bold absolute bg-primary text-white overflow-hidden z-30 flex-col flex items-center justify-center transition-all w-screen ${menuOpener ? "h-screen" : "h-0"} `}>
                <div><a className="transition-all duration-1000 transform hover:text-black" href="https://www.linkedin.com/in/josealjovin/">Linkedin</a></div>
                <div><a className="transition-all duration-1000 transform hover:text-black" href="https://www.facebook.com/jose.aljovin">Facebook</a></div>
                <div><a className="transition-all duration-1000 transform hover:text-black" href="https://www.youtube.com/josealjovin">Youtube</a></div>
                <div><a className="transition-all duration-1000 transform hover:text-black" href="https://www.instagram.com/josealjovin/">Instagram</a></div>
                <div><a className="transition-all duration-1000 transform hover:text-black" href="https://twitter.com/josealjovin">Twitter</a></div>
                <div><a className="transition-all duration-1000 transform hover:text-black" href="https://wa.me/51993375762">WhatsApp</a></div>
            </div>
        </>
    );
}